import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import RellaxWrapper from "react-rellax-wrapper";
import BlockWrapper from "../features/BlockWrapper";
import { useResponsive } from "../hooks/useResponsive";
import { breakpoints, rem, theme } from "../styling/theme";
import Background from "../ui/Cover";
import { getCoverUrl } from "../utils/helpers";
import { DataT, IntroT } from "../utils/types";

const Title = styled.h1({
  position: "absolute",
  margin: 0,
  left: rem(50),
  top: "30%",
  fontSize: rem(150),
  fontWeight: 400,
  lineHeight: rem(120),
  color: theme.color.primaryColor,
  transition: "all 0.3s cubic-bezier(0.22, 1, 0.36, 1)",
  textTransform: "uppercase",

  [theme.media.maxMd]: {
    fontSize: rem(120),
    lineHeight: rem(100),
  },

  [theme.media.maxSm]: {
    top: "35%",
    fontSize: rem(100),
    lineHeight: rem(85),
  },

  [theme.media.maxMi]: {
    fontSize: rem(65),
    lineHeight: rem(55),
    left: rem(20),
  },
});

const Intro: FC = () => {
  const { strapiCover } = useStaticQuery(query);
  const contactData: DataT<IntroT> = strapiCover;

  const { render } = contactData.data.attributes;

  const { tabletVersion, mobileVersion } = useResponsive(breakpoints.sm);

  return (
    <BlockWrapper
      cover={
        <Background
          url={getCoverUrl(
            tabletVersion || mobileVersion,
            render.desktop,
            render.mobile
          )}
        >
          <Title>
            <RellaxWrapper horizontal speed={0}>
              {"humanoid"}
              <br />
              {"research"}
              <br />
              {"lab."}
            </RellaxWrapper>
          </Title>
        </Background>
      }
    />
  );
};

export default Intro;

const query = graphql`
  query {
    strapiCover {
      data {
        attributes {
          render {
            id
            desktop {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
