import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import BlockWrapper from "../features/BlockWrapper";
import TextGrid, { TextGridWrapperAbsolute } from "../features/TextGrid";
import Background from "../ui/Cover";
import { DataT, VisionT } from "../utils/types";

const Vision: FC = () => {
  const { strapiVision } = useStaticQuery(query);
  const visionData: DataT<VisionT> = strapiVision;

  const { render, content } = visionData.data.attributes;

  return (
    <BlockWrapper
      idForHooks="vision"
      cover={
        <>
          <Background url={render && render.desktop.data.attributes.url} />
          {content && (
            <TextGridWrapperAbsolute>
              <TextGrid items={[content]} centerOnMobile={true} />
            </TextGridWrapperAbsolute>
          )}
        </>
      }
    ></BlockWrapper>
  );
};

export default Vision;

const query = graphql`
  query {
    strapiVision {
      data {
        attributes {
          content {
            highlight_color
            id
            text
            text_color
            paragraph_size
            width
            x_offset
            y_offset
          }
          render {
            desktop {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
