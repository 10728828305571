import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import BlockWrapper from "../features/BlockWrapper";
import TextGrid from "../features/TextGrid";
import { useResponsive } from "../hooks/useResponsive";
import { breakpoints, rem, theme } from "../styling/theme";
import Background from "../ui/Cover";
import FlexBox from "../ui/FlexBox";
import { getCoverUrl } from "../utils/helpers";
import { ContactT, DataT } from "../utils/types";

const Title = styled.h2({
  position: "absolute",
  margin: 0,
  left: "50%",
  top: "50%",
  fontSize: rem(60),
  fontWeight: 400,
  lineHeight: rem(120),
  color: theme.color.primaryColor,
  transition: "all 0.3s cubic-bezier(0.22, 1, 0.36, 1)",
  textTransform: "uppercase",
  transform: "translate(-50%,-100%)",
  whiteSpace: "pre",

  [theme.media.maxMd]: {
    fontSize: rem(45),
    top: "35%",
  },

  [theme.media.maxSm]: {
    top: "30%",
    fontSize: rem(35),
  },
});

const ContactsWrapper = styled(FlexBox)({
  position: "absolute",
  top: "auto",
  width: "100%",
  maxWidth: rem(1500),
  bottom: "15%",
  left: "50%",
  transform: "translateX(-50%)",
});

const Contact: FC = () => {
  const { strapiContact } = useStaticQuery(query);
  const contactData: DataT<ContactT> = strapiContact;

  const { render, title, text_blocks } = contactData.data.attributes;

  const { tabletVersion, mobileVersion } = useResponsive(breakpoints.sm);

  return (
    <BlockWrapper
      idForHooks="contact"
      cover={
        <>
          <Background
            url={getCoverUrl(
              tabletVersion || mobileVersion,
              render.desktop,
              render.mobile
            )}
          />
          <Title>{title}</Title>
          {text_blocks && (
            <ContactsWrapper>
              <TextGrid items={text_blocks} />
            </ContactsWrapper>
          )}
        </>
      }
    />
  );
};

export default Contact;

const query = graphql`
  query {
    strapiContact {
      data {
        attributes {
          text_blocks {
            highlight_color
            id
            text
            text_color
            width
            x_offset
          }
          title
          render {
            desktop {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
