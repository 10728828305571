import React, { FC } from "react";
import { useQuery } from "react-query";
import Claim from "../partials/Claim";
import Contact from "../partials/Contact";
import Intro from "../partials/Intro";
import Menu from "../partials/Menu";
import Sections from "../partials/Sections";
import Vision from "../partials/Vision";
import FlexBox from "../ui/FlexBox";
import PageWrapper from "../ui/PageWrapper";
import { getData } from "../utils/getData";
import { CollectionDataT, SectionT } from "../utils/types";

const HomePage: FC = () => {
  const { data, isLoading } = useQuery("home-page", () =>
    getData<CollectionDataT<SectionT>>({
      apiUrl: `${process.env.API_URL}/portfolios?populate=deep`,
    })
  );

  return (
    <PageWrapper dataIsLoading={isLoading}>
      <FlexBox flexDirection="column" style={{ scrollSnapType: "y mandatory" }}>
        <Menu />
        <Claim />
        <Intro />
        <Vision />
        <Sections sections={data} />
        <Contact />
      </FlexBox>
    </PageWrapper>
  );
};

export default HomePage;
